import { DataAccess } from "../dataaccess/data.access";
import { Cache, Invalidate } from "../decorators/cache.decorator";
import { Timeout } from "../decorators/timeout.decorator";
import { S25Util } from "../util/s25-util";
import { FlsService } from "./fls.service";
import { PreferenceService } from "./preference.service";

const ORGANIZATION_ARRAYS = {
    organization: true,
    category: true,
    organization_type: true,
    contact: true,
    custom_attribute: true,
    address: true,
};
const SERVICE = "OrganizationService";
export class OrganizationService {
    public static putPromise: Promise<any>[] = [];

    @Invalidate({ serviceName: "OrganizationService" })
    public static putOrganization(itemId: number, payload: any) {
        return OrganizationService.getOrganizationById(itemId, []).then(function (item) {
            S25Util.coalesceDeep(payload, item);
            delete payload.crc;
            return DataAccess.put(
                DataAccess.injectCaller("/organization.json?organization_id=" + itemId, SERVICE + ".putOrganization"),
                S25Util.getPayload("organizations", "organization", "organization_id", "mod", itemId, payload),
            );
        });
    }

    @Cache({ targetName: "OrganizationService", immutable: true })
    public static getOrganizationsById(idArray: number[], includes?: any) {
        includes = S25Util.array.forceArray(includes);
        let includeStr = !includes ? "" : "&scope=extended&include=" + includes.join("+");
        return DataAccess.get(
            DataAccess.injectCaller(
                "/organizations.json?organization_id=" + idArray.join("+") + includeStr,
                SERVICE + "getOrganizationsById",
            ),
        ).then(function (data) {
            let ret = data && S25Util.prettifyJson(data, null, ORGANIZATION_ARRAYS);
            return ret && ret.organizations && ret.organizations.organization;
        });
    }

    @Timeout
    public static getOrganizationById(id: number, includes?: any) {
        //note: getOrganizationById uses accl organizations.xml (plural) handled by accl, much faster than uniface-handled organization.xml (singular)
        return OrganizationService.getOrganizationsById([id], includes).then(function (organizations) {
            if (organizations && organizations.length) {
                return organizations[0];
            }
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "OrganizationService" })
    public static getOrganizationContacts(id: number) {
        return OrganizationService.getOrganizationById(id, ["contacts"]).then(function (data) {
            return (data && data.contact) || [];
        });
    }

    @Timeout
    public static deleteOrganization(id: number) {
        return DataAccess.delete(
            DataAccess.injectCaller(
                "/organization.json?organization_id=" + id,
                "OrganizationService.deleteOrganization",
            ),
        ).then(
            function (resp) {
                resp = S25Util.prettifyJson(resp);
                var isSuccess =
                    (resp && resp.results && resp.results.info && resp.results.info.msg_id) === "AC_I_DELETED";
                return { error: !isSuccess, success: isSuccess };
            },
            function (error) {
                console.log(error);
                return { error: error, success: false };
            },
        );
    }

    // don't use @Timeout since it can return null. Could type the response explicitly if there are timeout problems
    @Cache({ immutable: true, targetName: "OrganizationService" })
    public static getOrganizationRatings(ids: any) {
        ids = S25Util.array.forceArray(ids);
        return DataAccess.get(
            DataAccess.injectCaller(
                "/organizations.json?organization_id=" + ids.join("+") + "&scope=minimal",
                SERVICE + ".getOrganizationRatings",
            ),
        ).then(
            function (data) {
                var ret = data && S25Util.prettifyJson(data, null, ORGANIZATION_ARRAYS);
                ret = ret && ret.organizations && ret.organizations.organization;
                return S25Util.array.forceArray(ret).map(function (org: any) {
                    return {
                        itemId: parseInt(org.organization_id),
                        itemName: org.organization_name,
                        ratingId: S25Util.coalesce(parseInt(S25Util.propertyGetVal(org, "rating_id")), -999),
                        ratingName: S25Util.propertyGetVal(org, "rating_name"),
                        ratingLevel: S25Util.coalesce(org.rating_events, 2),
                    };
                });
            },
            function () {
                return null;
            },
        );
    }

    @Timeout
    @Cache({ immutable: true, targetName: "OrganizationService" })
    public static getOrganizationRatingStandings(ids: any) {
        return S25Util.all({
            ratings: OrganizationService.getOrganizationRatings(ids),
            pref: PreferenceService.getPreferences(["config_org_ratings"]),
            fls: FlsService.getFls(),
        }).then(
            function (resp) {
                let hasRatings = resp.fls.CU_ACCT_RATING !== "N";
                if (resp.ratings && resp.pref.config_org_ratings && resp.pref.config_org_ratings.value) {
                    return resp.ratings.map(function (rating: any) {
                        var ratingConfig = S25Util.propertyGetParentWithChildValue(
                            resp.pref.config_org_ratings.value,
                            "rating_id",
                            rating.ratingId,
                        );
                        return {
                            itemId: rating.itemId,
                            itemName: rating.itemName,
                            ratingName: rating.ratingName || "",
                            ratingLevel: rating.ratingLevel,
                            organizationStanding: (ratingConfig && ratingConfig.standing) || 2,
                        };
                    });
                }
            },
            function () {
                return [];
            },
        );
    }

    /**
     * @summary
     *  Get organization data
     * @param
     *  {(number|number[])} id - unique object identifier
     * @return
     *  {Promise} (organization|organization[])
     */
    public static getOrganization(id: number) {
        return DataAccess.get(
            DataAccess.injectCaller("/organization.json?organization_id=" + id, SERVICE + ".getOrganization"),
        ).then(function (data) {
            var ret = data && S25Util.prettifyJson(data, null, ORGANIZATION_ARRAYS); //true nodes should be an array
            return ret && ret.organizations && ret.organizations.organization && ret.organizations.organization[0];
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "OrganizationService" })
    public static getOrganizationNameAndFormal(id: number) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/organization.json?organization_id=" + id + "&scope=minimal",
                SERVICE + ".getOrganizationNameAndFormal",
            ),
        ).then(function (data) {
            data = data && S25Util.prettifyJson(data);
            if (!data.organizations.organization)
                return {
                    itemName: "(Private)",
                    itemFormal: "",
                };
            return {
                itemName: S25Util.propertyGetVal(data, "organization_name"),
                itemFormal: S25Util.propertyGetVal(data, "organization_title"),
            };
        });
    }

    @Timeout
    public static getOrganizationName(id: number) {
        return OrganizationService.getOrganizationNameAndFormal(id).then(function (data) {
            return data.itemName;
        });
    }

    @Timeout
    public static getOrganizationFormal(id: number) {
        return OrganizationService.getOrganizationNameAndFormal(id).then(function (data) {
            return data.itemFormal;
        });
    }

    @Invalidate({ serviceName: "SearchCriteriaDao", methodName: "getEventOrganizations" })
    public static createOrganization(
        name: string,
        title: string,
        typeId?: number,
        addressBean?: any,
        customAttrsBean?: any,
    ) {
        var data = {
            organizations: {
                organization: [
                    {
                        status: "new",
                        organization_name: name,
                        organization_title: title,
                        organization_type: {
                            status: "new",
                            type_id: typeId,
                        },
                        address: {
                            status: "new",
                            address_type: 2,
                            street_address: addressBean?.street,
                            city: addressBean?.city,
                            state_prov: addressBean?.state,
                            zip_post: addressBean?.zip,
                            country: addressBean?.country,
                            phone: addressBean?.phone,
                            fax: addressBean?.fax,
                        },
                        custom_attribute: customAttrsBean?.item.map(function (custAttr: any) {
                            return {
                                status: "new",
                                attribute_id: custAttr?.custAttrId,
                                attribute_type: custAttr?.itemTypeId,
                                attribute_value: window.angBridge.$injector
                                    .get("CustomAttrItemFormatter")
                                    .putFormat(custAttr, custAttr.data, true),
                            };
                        }),
                    },
                ],
            },
        };
        return DataAccess.post(
            DataAccess.injectCaller("/organizations.json", SERVICE + ".createOrganization"),
            data,
        ).then(function (data) {
            return data;
        });
    }

    //converts the contact role format from WS to something more usable
    public static toContactRoleObj(contact: any) {
        return {
            itemName: contact.contact_name,
            itemId: contact.contact_id,
            itemTitle: contact.contact_title,
            itemEmail: contact.contact_email,
            itemTypeId: 3,
            isPrimary: contact.primary_contact && contact.primary_contact === "T",
            role: {
                itemId: contact.contact_role_id,
                itemName: contact.contact_role,
            },
        };
    }

    public static toContactRoleNode(objRole: any) {
        return {
            contact_name: objRole.itemName,
            contact_id: objRole.itemId,
            contact_title: objRole.itemTitle,
            contact_email: objRole.itemEmail,
            itemTypeId: 3,
            primary_contact: objRole.isPrimary ? "T" : "F",
            contact_role_id: objRole.role && objRole.role.itemId,
            contact_role: objRole.role && objRole.role.itemName,
            status: objRole.status,
        };
    }

    @Timeout
    public static updateType(ids: number[], typeId: number) {
        return OrganizationService.updateBulk(ids, "type", typeId);
    }

    @Timeout
    public static updateRating(ids: number[], ratingId: number) {
        return OrganizationService.updateBulk(ids, "rating", ratingId);
    }

    @Timeout
    public static updateAccountingCode(ids: number[], acctCode: string) {
        return DataAccess.put(
            DataAccess.injectCaller("/organization/accounting/code.json", "OrganizationService.updateCode"),
            {
                root: {
                    accounting_code: acctCode,
                    organizations: ids.map((id) => {
                        return { organization_id: id };
                    }),
                },
            },
        );
    }

    /*
	updateType - the name of thing to be updated on the org ('rating','type', etc.)
	ids - an array of organization Ids
	itemId - new valueId for the thing
	*/
    public static updateBulk(orgIds: number[], updateType: string, itemId: number) {
        let valKey = updateType + "_id";
        return DataAccess.put(
            DataAccess.injectCaller("/organization/" + updateType + ".json", "OrganizationService.updateBulk"),
            {
                root: {
                    [valKey]: itemId,
                    organizations: orgIds.map((id) => {
                        return { organization_id: id };
                    }),
                },
            },
        );
    }

    // update comments / default set-up  instructions
    public static updateText(ids: number[], type: string, text: string) {
        return DataAccess.put(DataAccess.injectCaller("/organization/text.json?", "OrganizationService.updateText"), {
            root: {
                type: type,
                text_comment: text,
                organizations: ids.map(function (id) {
                    return { organization_id: id };
                }),
            },
        });
    }

    public static updateCategories(ids: number[], addIds: [], removeIds: []) {
        return DataAccess.put(
            DataAccess.injectCaller("/organization/categories.json", "OrganizationService.updateCategories"),
            {
                root: {
                    cats_add: addIds.map(function (a: any) {
                        return { cat_id: a.itemId };
                    }),
                    cats_remove: removeIds.map(function (a: any) {
                        return { cat_id: a.itemId };
                    }),
                    organizations: ids.map(function (id) {
                        return { organization_id: id };
                    }),
                },
            },
        );
    }
    //Orgs [{organization_id: number, primary_cont_id: number -999 sets to null]
    //Contacts [{cont_id: number, role_id: number - empty role_id means no role}]
    //May return status 400 - contact can only have one role
    public static updateAssociatedContacts(orgs: any[], contacts: any[], del?: boolean) {
        let url = DataAccess.injectCaller(
            "/organization/contact/role.json",
            "OrganizationService.updateAssociatedContacts",
        );
        let payload = {
            root: {
                organizations: orgs,
                contacts: contacts,
            },
        };

        if (del) {
            return DataAccess.delete(url, payload);
        } else {
            return DataAccess.put(url, payload);
        }
    }

    //empty query_id removes any/all preferences
    public static updatePartitionPrefs(orgIds: number[], queryId: number) {
        let payload = {
            root: {
                query_id: queryId || "",
                organizations: orgIds.map((orgId) => {
                    return { organization_id: orgId };
                }),
            },
        };
        return DataAccess.put("/organization/partition/preference.json", payload);
    }

    public static copyOrganization = function (itemId: number, newItem: any, fields?: any, securityParams?: string) {
        return OrganizationService.getOrganizationById(itemId, [
            "address",
            "contacts",
            "preferences",
            "categories",
            "attributes",
            "text",
        ]).then((orig) => {
            let newObject = S25Util.deepCopy(orig);
            newObject.organization_id = "";
            newObject.organization_name = newItem.itemName || "";
            newObject.organization_title = newItem.itemFormal || "";
            S25Util.replaceDeep(newObject, { status: "new" });

            return FlsService.getFls().then((fls) => {
                if (fls.CU_ACCT_PREF != "F") {
                    delete newObject.space_preference;
                }
                // if(fls.CU_ACCOUNT_RATING != "F"){
                // 	newObject.rating_id = "";
                // }
                // if(fls.CU_COMMENTS != "F") {
                // 	newObject.coments = "";
                // }
                delete newObject.crc;

                const url = DataAccess.injectCaller(
                    `/organizations.json?${securityParams}`,
                    "OrganizationService.copyOrganization",
                );

                return DataAccess.post(
                    url,
                    S25Util.getPayload("organizations", "organization", "organization_id", "new", itemId, newObject),
                ).then(function (resp) {
                    return resp?.results?.info?.id;
                });
            });
        });
    };

    @Timeout
    public static deleteOrganizations(ids: any) {
        let payload: any = { map: { organization_id: ids } };
        return DataAccess.delete(
            DataAccess.injectCaller("/organizations.json", "OrganizationService.deleteOrganizations"),
            payload,
        ).then(
            function (resp) {
                resp = S25Util.prettifyJson(resp);
                let isSuccess =
                    (resp && resp.results && resp.results.info && resp.results.info.msg_id) === "AC_I_DELETED";
                let noPerms =
                    resp &&
                    resp.results &&
                    resp.results.noPerm &&
                    resp.results.noPerm.item &&
                    S25Util.array.forceArray(resp.results.noPerm.item).map((item: any) => {
                        return {
                            itemName: item.object_name,
                            itemId: item.object_id,
                            itemTypeId: item.object_type,
                        };
                    });
                return { error: !isSuccess, success: isSuccess, noPerms: { items: noPerms } };
            },
            function (error) {
                S25Util.showError(error);
                return { error: error, success: false };
            },
        );
    }
}
