//@author: devin
import { S25Util } from "../util/s25-util";
import { DataAccess } from "../dataaccess/data.access";
import { jSith } from "../util/jquery-replacement";
import { Cache, CacheRepository, Invalidate } from "../decorators/cache.decorator";
import { Preference } from "../pojo/PeferenceI";
import { Proto } from "../pojo/Proto";
import NumericalString = Proto.NumericalString;
import LowerStringBoolean = Proto.LowerStringBoolean;
import { S25Const } from "../util/s25-const";

//ANG-2713 - some preferences store html and we don't want to convert it to json objects - TW
const PREF_VALUE_FORMATS: any = {
    EmailSignature: "html",
};
export class PreferenceService {
    public static formCalendarView(weekStart: number, existingCalendarView?: string) {
        return (
            weekStart +
            (existingCalendarView && existingCalendarView.indexOf("/;") > -1 ? existingCalendarView.substring(1) : "")
        );
    }

    public static async getOfficeHours() {
        const preferences = await PreferenceService.getPreferences(["OfficeHours"]);
        const start = PreferenceService.extractDisplayStartAsInt(preferences?.OfficeHours?.value);
        const end = PreferenceService.extractDisplayEndAsInt(preferences?.OfficeHours?.value);
        return { start, end };
    }

    public static formOfficeHours(
        displayStartHour: any,
        displayEndHour: any,
        displayEndMinute?: number,
        existingOfficeHours?: string,
    ) {
        var newOfficeHours = "";
        var displayStartHourInt = S25Util.date.isDate(displayStartHour)
            ? displayStartHour.getHours()
            : parseInt(displayStartHour);
        var displayEndHourInt = S25Util.date.isDate(displayEndHour)
            ? displayEndHour.getHours()
            : parseInt(displayEndHour);
        var displayEndMinuteInt = parseInt(
            String(displayEndMinute || (S25Util.date.isDate(displayEndHour) ? displayEndHour.getMinutes() : 0)),
        );
        var displayStartString = "00000000" + (S25Util.leftPad(displayStartHourInt, 2) + "000000");
        var displayEndString =
            "00000000" + S25Util.leftPad(displayEndHourInt, 2) + S25Util.leftPad(displayEndMinuteInt, 2) + "0000";
        if (!existingOfficeHours || existingOfficeHours.length === 0) {
            newOfficeHours = displayStartString + "\\;" + displayEndString + "\\;1!;2!;3!;4!;5!;6!;7";
        } else {
            newOfficeHours =
                displayStartString +
                "\\;" +
                displayEndString +
                "\\;" +
                existingOfficeHours.split(";").splice(2).join(";");
        }
        return newOfficeHours;
    }

    public static extractWeekStart(calendarView: string) {
        return calendarView.substring(0, 1);
    }

    public static extractOfficeHours(officeHours: string) {
        officeHours = officeHours.replace(/:/g, ""); //no colon separator between times
        officeHours = officeHours.replace(/\\/g, ""); //get rid of slashes
        var parts = officeHours.split(";"); //split on delimiter for this pref value
        var startHour = 0,
            startMin = 0,
            endHour = 23,
            endMin = 59;

        if (parts.length >= 2) {
            var startPart = parts[0],
                endPart = parts[1];
            if (startPart.length === 16) {
                //many parts have 4 trailing zeros (for milliseconds) so we chop those off
                startPart = startPart.substring(0, 12);
            }

            if (endPart.length === 16) {
                endPart = endPart.substring(0, 12);
            }

            if (parseInt(startPart) !== 0 && startPart.length >= 4) {
                //get last 4th to last until 3rd to last digit for start hour
                startHour =
                    Math.min(parseInt(startPart.substring(startPart.length - 4, startPart.length - 2)), 23) || 0;
                //get last 2nd to last until last for start minute
                startMin = Math.min(parseInt(startPart.substring(startPart.length - 2, startPart.length)), 59) || 0;
            }

            if (parseInt(endPart) !== 0 && endPart.length >= 4) {
                endHour = Math.min(parseInt(endPart.substring(endPart.length - 4, endPart.length - 2)), 23);
                endHour = isNaN(endHour) ? 23 : endHour;

                endMin = Math.min(parseInt(endPart.substring(endPart.length - 2, endPart.length)), 59);
                endMin = isNaN(endMin) ? 59 : endMin;
            }
        }

        if (startHour === endHour) {
            startHour = 0;
            endHour = 23;
        }

        var displayStartDate = new Date();
        displayStartDate.setSeconds(0);
        displayStartDate.setMilliseconds(0);
        displayStartDate.setHours(startHour);
        displayStartDate.setMinutes(startMin);

        var displayEndDate = new Date();
        displayEndDate.setSeconds(0);
        displayEndDate.setMilliseconds(0);
        displayEndDate.setHours(endHour);
        displayEndDate.setMinutes(endMin);

        return { displayStartDate: displayStartDate, displayEndDate: displayEndDate };
    }

    public static extractDisplayStart(officeHours: string) {
        return PreferenceService.extractOfficeHours(officeHours).displayStartDate;
    }

    public static extractDisplayEnd(officeHours: string) {
        return PreferenceService.extractOfficeHours(officeHours).displayEndDate;
    }

    public static extractDisplayStartAsInt(officeHours: string) {
        return !officeHours ? 0 : PreferenceService.extractOfficeHours(officeHours).displayStartDate.getHours();
    }

    public static extractDisplayEndAsInt(officeHours: string) {
        return !officeHours ? 23 : PreferenceService.extractOfficeHours(officeHours).displayEndDate.getHours() + 1;
    }

    @Cache({ immutable: true, targetName: "PreferenceService" })
    public static getGenericPreference(prefType: string, prefNames: string | string[]) {
        let prefNamesArr = S25Util.array.forceArray(prefNames);
        return DataAccess.get(
            DataAccess.injectCaller(
                "/genpref/preference.json?pref_name=" + prefNamesArr.join("+") + "&pref_type=" + prefType,
                "PreferenceService.getGenericPreference",
            ),
        ).then(function (prefs: any) {
            return PreferenceService.formatGenPreferences(prefs);
        });
    }

    public static formatGenPreferences(prefs: any) {
        let respObj: any = {};
        if (prefs && prefs.prefs) {
            for (let i = 0, len = prefs.prefs.length; i < len; i++) {
                if (prefs.prefs[i].pref_name) {
                    respObj[prefs.prefs[i].pref_name] = prefs.prefs[i].pref_value;
                }
            }
        }
        return respObj;
    }

    public static async getPreferences<T extends string, PT extends Preference.PrefType>(
        prefnames: T[],
        prefType?: PT,
    ): Promise<{ [Property in T]: Preference.Pref<PT> }> {
        const prefs = S25Util.array.forceArray(prefnames);

        // Check cache for preferences
        const cache: { [Property in T]: Promise<Preference.Pref<PT>> } = {} as any;
        const misses: T[] = [];
        for (let pref of prefs) {
            const key = CacheRepository.composeKey("PreferenceService", "getPreferences", [pref, prefType]);
            const data = CacheRepository.get(key) as Promise<Preference.Pref<PT>>;

            if (data !== undefined) {
                cache[pref] = data; // Cache hit
            } else {
                misses.push(pref); // Cache miss
            }
        }

        if (misses.length) {
            // Fetch uncached prefs
            let queryParam = `?name=${misses.join("+")}`;
            if (prefType) queryParam = `?type=${prefType}&name=${misses.join("+")}`;
            const dataPromise = DataAccess.get(
                DataAccess.injectCaller(`/preference.json${queryParam}`, "PreferenceDao.getPreferences"),
            ).then(PreferenceService.formatPreferences);

            // Cache uncached prefs
            for (let pref of misses) {
                const key = CacheRepository.composeKey("PreferenceService", "getPreferences", [pref, prefType]);
                const prefPromise = dataPromise.then((data) => data[pref] as Preference.Pref<PT>);
                CacheRepository.put(key, prefPromise);
                cache[pref] = prefPromise;
            }
        }

        // Return all prefs
        return S25Util.all(cache);
    }

    public static formatPreferences(data: Preference.wsPref): Preference.prefData {
        let respObj: any = {};
        let preferences = (data && data.preferences && S25Util.array.forceArray(data.preferences.preference)) || [];
        for (let i = 0; i < preferences.length; i++) {
            if (preferences[i].preference_name) {
                let value = preferences[i].preference_value;
                let name = preferences[i].preference_name;
                if (
                    value &&
                    value.indexOf &&
                    value.indexOf("<") === 0 &&
                    !S25Util.contains(PREF_VALUE_FORMATS[name], "html")
                ) {
                    //convert xml as text value to json
                    value = S25Util.prettyConv(value);
                } else if (
                    value &&
                    value.indexOf &&
                    value.indexOf("&lt;") === 0 &&
                    S25Util.contains(PREF_VALUE_FORMATS[name], "html")
                ) {
                    //ANG-2713 - some preferences store html and we don't want to convert it to json objects -TW
                    value = S25Util.unescapeXml(value);
                }
                respObj[name] = {
                    value: value,
                    type: preferences[i].preference_type,
                };
            }
        }
        return respObj;
    }

    @Invalidate({ startsWith: "PreferenceService.getGenericPreference", patternFunc: (args) => args[1] })
    public static setGenericPreference(prefType: string, prefName: string, prefValue: string) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/genpref/preference.json?pref_name=" +
                    prefName +
                    "&pref_value=" +
                    prefValue +
                    "&pref_type=" +
                    prefType +
                    "&pref_op=update",
                "PreferenceService.setGenericPreference",
            ),
        );
    }

    @Invalidate({ startsWith: "PreferenceService.getPreferences", patternFunc: (args) => args[0] })
    public static setPreference(prefname: string, newValue: any, prefType?: string) {
        prefType = prefType || "U";
        if (S25Util.isObject(newValue)) {
            newValue = S25Util.xml.json2xml_str(newValue);
        }
        return DataAccess.put(
            DataAccess.injectCaller(
                "/preference.json?type=" + prefType + "&name=" + prefname,
                "PreferenceDao.setPreference",
            ),
            {
                preferences: {
                    preference: {
                        status: "mod",
                        preference_name: prefname,
                        preference_value: newValue,
                        preference_type: prefType,
                    },
                },
            },
        );
    }

    @Invalidate({ startsWith: "PreferenceService.getPreferences", patternFunc: (args) => args[0] })
    public static updateSearchColListPref(prefname: string, colList: any[]) {
        //note: preference value, if xml, is always sent as xml string and saved literally so it
        //undergoes no conversion

        let prefvalue = '<columns xmlns="http://www.w3.org/1999/xhtml">';
        jSith.forEach(colList, function (_: any, value: any) {
            if (value && !value.iconType && value.prefname && value.name) {
                prefvalue +=
                    '<column name="' +
                    value.prefname +
                    '" show="' +
                    (value.isVisible === 1 ? "true" : "false") +
                    '" title="' +
                    value.name +
                    '" />';
            }
        });
        prefvalue += "</columns>";

        return PreferenceService.setPreference(prefname, prefvalue, "U");
    }
}

export type DaysOfWeekPref = {
    days: {
        day: {
            name: "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday";
            position: NumericalString;
            show: LowerStringBoolean;
            dow: 0 | 1 | 2 | 3 | 4 | 5 | 6;
        }[];
    };
};
