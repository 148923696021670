import { DataAccess } from "../dataaccess/data.access";
import { Cache } from "../decorators/cache.decorator";
import { Timeout } from "../decorators/timeout.decorator";
import { jSith } from "../util/jquery-replacement";
import { S25Util } from "../util/s25-util";
import { EventIncludeOption, EventService } from "./event.service";
import { FormatService } from "./format.service";
import { ListGeneratorService } from "./list.generator.service";
import {
    AccountOccurrenceSubtotal,
    AccountProfileSubTotal,
    AccountSubTotal,
    Billing,
    LineItem,
    LineItemI,
    MicroBilling,
    PricingOccurrence,
    RateGroupListItem,
    TaxListItem,
} from "../pojo/Pricing";
import { Proto } from "../pojo/Proto";
import { S25Datefilter } from "../modules/s25-dateformat/s25.datefilter.service";
import ISODateString = Proto.ISODateString;
import { TelemetryService } from "./telemetry.service";

export class PricingService {
    public static PricingConst = {
        cols: [
            { name: "Reference", prefname: "reference", sortable: 1, isDefaultVisible: 1 },
            { name: "Item", prefname: "item", sortable: 1, isDefaultVisible: 1 },
            { name: "List Price", prefname: "listPrice", sortable: 1, isDefaultVisible: 1, isMoney: 1 },
            { name: "Adjustments", prefname: "adjust", sortable: 1, isDefaultVisible: 1, templateType: 12 },
            { name: "Price", prefname: "price", sortable: 1, isDefaultVisible: 1, isMoney: 1 },
            { name: "Taxes", prefname: "tax", sortable: 1, isDefaultVisible: 1, templateType: 15 },
            { name: "Total", prefname: "total", sortable: 1, isDefaultVisible: 1, isMoney: 1 },
            { name: "Invoice To", prefname: "invoice", sortable: 1, isDefaultVisible: 1, isMoney: 1 },
            { name: "Charge To", prefname: "charge", sortable: 1, isDefaultVisible: 1, templateType: 13 },
            { name: "Rate Schedule", prefname: "schedule", sortable: 1, isDefaultVisible: 1 },
            { name: "Rate Group", prefname: "group", sortable: 1, isDefaultVisible: 1, templateType: 14 },
            { name: "Debit Account", prefname: "debit", sortable: 1, isDefaultVisible: 1 },
            { name: "Credit Account", prefname: "credit", sortable: 1, isDefaultVisible: 1 },
        ],
    };

    @Timeout
    @Cache({ immutable: true, targetName: "PricingService" })
    public static getRatesAndTaxes() {
        return DataAccess.get<{
            root: { rate_groups: { rate_group: RateGroupListItem[] }; taxes: { tax: TaxListItem[] } };
        }>(DataAccess.injectCaller("/pricing/rates_taxes.json", "PricingService.getRatesAndTaxes")).then((data) => {
            // Force tax rate group list into array
            for (let tax of data?.root?.taxes?.tax || []) tax.rate_groups = S25Util.array.forceArray(tax.rate_groups);
            return data;
        });
    }

    //note: pricing data is NOT all combined like it is in getPricing with combineRelatedEvents set to true (for list)
    //this just returns all events and their pricing data separated into each event from the API
    public static getPricingRelatedEvents(eventId: number, includeRelatedEvents: boolean) {
        //billing: bill items
        //customers: organizations on event
        //profile: name and date info for profile on each event
        var includes: EventIncludeOption[] = ["billing", "customers", "profile"];
        if (includeRelatedEvents) {
            return EventService.getEventRelationships(eventId).then(function (rel) {
                if (rel && rel.content && rel.content.length) {
                    var eventIds = [eventId];
                    jSith.forEach(rel.content, function (_, c) {
                        eventIds.push(c.content_event_id);
                    });
                    return EventService.getEventsInclude(eventIds, includes);
                } else {
                    return EventService.getEventsInclude(eventId, includes);
                }
            });
        } else {
            return EventService.getEventsInclude(eventId, includes);
        }
    }

    public static getPricingAndEventsFromPricingSetId(eventId: number, evBillId: number) {
        return PricingService.getPricingFromPricingSetId(eventId, evBillId).then(function (pricingEventData) {
            return EventService.getEventsInclude(pricingEventData.eventIds, ["customers", "profile"]).then(
                function (events) {
                    jSith.forEach(events, function (_, event) {
                        event.bill_item = [];
                        jSith.forEach(pricingEventData.bill_item, function (_, billItem) {
                            if (parseInt(billItem.eventId) === parseInt(event.event_id)) {
                                event.bill_item.push(billItem);
                            }
                        });
                    });
                    return events;
                },
            );
        });
    }

    public static getPricingFromPricingSetId(eventId: number, evBillId: number) {
        return PricingService.getPricingSet(evBillId).then(function (pricingSet) {
            return PricingService.getPricingFromPricingSet(eventId, pricingSet);
        });
    }

    public static getPricingFromPricingSet(eventId: number, pricingSet?: MicroBilling) {
        if (!pricingSet?.data?.items?.[0]) return;

        const { rateGroups, rateSchedules, organizations, profiles, occurrences } = pricingSet.expandedInfo;
        const bill = pricingSet.data.items[0];
        const { billDefn, billing, id } = bill;

        const groupNames = S25Util.fromEntries(rateGroups.map((group) => [group.rateGroup_id, group.rateGroupName]));
        const rateNames = S25Util.fromEntries(rateSchedules.map((schedule) => [schedule.rateId, schedule.rateName]));
        const orgNames = S25Util.fromEntries(organizations.map((org) => [org.organizationId, org.organizationName]));
        const profileNames = S25Util.fromEntries(profiles.map((profile) => [profile.profileId, profile.name]));
        const occNames = S25Util.fromEntries(occurrences.map((occ) => [occ.rsrvId, occ.occurrence]));

        let ret: any = {
            event_id: eventId,
            eventIds: S25Util.propertyGetAllUnique(bill, "eventId").filter(function (id: any) {
                return id > 0;
            }),
            eventsNeedingRefresh: [],
            organization: [],
            orgToEvents: {},
            event_history: [
                {
                    history_type_id: 4,
                    history_dt: billDefn.billDate,
                },
            ],
            bill_item: [],
            evBillId: id,
            occSubtotals: billing.subtotals[0].accountOccurrence,
            occurrences: occurrences,
            profileSubtotals: billing.subtotals[0].accountProfile,
        };

        return S25Util.all({
            eventsNeedingRefresh: EventService.getEventsNeedingRefresh(ret.eventIds),
            allEventData: EventService.getEventsInclude(ret.eventIds, ["customers"]),
        }).then(function (resp) {
            let eventIdMap: any = {};

            ret.eventsNeedingRefresh = resp.eventsNeedingRefresh;

            jSith.forEach(resp.allEventData, function (_, event) {
                eventIdMap[event.event_id] = event;

                jSith.forEach(event.organization, function (_, org) {
                    let orgId = parseInt(org.organization_id);
                    let eventId = parseInt(event.event_id);
                    if (!ret.orgToEvents[orgId]) {
                        ret.orgToEvents[orgId] = {};
                    }
                    ret.orgToEvents[orgId][eventId] = true;

                    org.evBillId = id;
                    org.organization_id = orgId; //id as integer

                    ret.organization.push(org);
                });
            });

            ret.organization = S25Util.array.uniqueByProp(ret.organization, "organization_id");

            const eventId = billDefn.events[0];
            // let eventLocator = eventIdMap[eventId]?.event_locator;
            jSith.forEach(billing.lineItems, function (_, lineItem) {
                lineItem = S25Util.prettifyJson(S25Util.camelToSnakeObj(lineItem), {
                    item_id: "bill_item_id",
                    item_name: "bill_item_name",
                    item_type: "bill_item_type_id",
                    profile_id: "ev_dt_profile_id",
                    total: "total_charge",
                    tax: "total_tax",
                });

                lineItem.tax = lineItem.taxes ? Object.values(lineItem.taxes) : [];
                delete lineItem.taxes;

                lineItem.occurrences = lineItem.occurrences
                    ? Object.values(lineItem.occurrences).map((row: PricingOccurrence) => ({
                          occurrence: occNames[row.rsrv_id],
                          adjustmentAmt: row.adjustment_amt,
                          adjustmentPercent: row.adjustment_percent,
                          adjustmentName: row.adjustment_name,
                          listPrice: row.list_price,
                          price: row.price,
                          rsrvId: row.rsrv_id,
                          total: row.total_charge,
                      }))
                    : [];

                lineItem.taxable_amt = lineItem.taxable_amt || lineItem.price;

                lineItem.eventId = eventId;
                lineItem.eventLocator = eventIdMap[lineItem.event_id]?.event_locator;
                lineItem.evBillId = id;

                if (S25Util.isDefined(lineItem.adjustment_percent)) {
                    lineItem.adjustment_percent = lineItem.adjustment_percent / 100 + "";
                }
                if (S25Util.isDefined(lineItem.adjustment_amt)) {
                    lineItem.adjustment_amt += "";
                }

                lineItem.charge_to_name = orgNames[lineItem.charge_to_id];
                lineItem.rate_group_name = groupNames[lineItem.rate_group_id];
                lineItem.rate_name = rateNames[lineItem.rate_id];
                lineItem.bill_profile_name = profileNames[lineItem.ev_dt_profile_id] || "";

                ret.bill_item.push(lineItem);
            });

            jSith.forEach(billing.adjustments, function (_, adjustment) {
                adjustment = S25Util.camelToSnakeObj(adjustment);
                adjustment.eventId = eventId; //add an event id here so we can tie it back to event when making pricing data from set
                adjustment.bill_item_id = adjustment.item_id;
                adjustment.bill_item_type_id = -1;
                adjustment.ev_dt_profile_id = -2;

                adjustment.total_tax = adjustment.total_tax || adjustment.tax;
                adjustment.tax = adjustment.taxes;
                delete adjustment.taxes;

                adjustment.evBillId = id;

                if (S25Util.isDefined(adjustment.adjustment_percent)) {
                    adjustment.adjustment_percent = adjustment.adjustment_percent / 100 + "";
                }
                if (S25Util.isDefined(adjustment.adjustment_amt)) {
                    adjustment.adjustment_amt += "";
                }

                adjustment.charge_to_name = orgNames[adjustment.charge_to_id];

                ret.bill_item.push(adjustment);
            });

            return ret;
        });
    }

    @Timeout
    public static getPricing(eventId: number, combineRelatedEvents: boolean) {
        var i = 0;
        //billing: bill items
        //text: old invoice data
        //history: bill date
        //customers: organizations on event
        var includes: EventIncludeOption[] = ["billing", "text", "history", "customers"];
        !combineRelatedEvents && includes.push("relationships"); //needed to show msg to user that related events exist if they wish to combine
        if (combineRelatedEvents) {
            return EventService.getEventRelationships(eventId).then(function (rel) {
                if (rel && rel.content && rel.content.length) {
                    var eventIds = [eventId];
                    for (i = 0; i < rel.content.length; i++) {
                        eventIds.push(rel.content[i].content_event_id);
                    }

                    return S25Util.all({
                        events: EventService.getEventsInclude(eventIds, includes),
                        eventsNeedingRefresh: EventService.getEventsNeedingRefresh(eventIds),
                    }).then(function (resp) {
                        var contextEvent = S25Util.propertyGetParentWithChildValue(resp.events, "event_id", eventId);
                        var relatedEvents = [];
                        for (i = 0; i < resp.events.length; i++) {
                            if (resp.events[i] && parseInt(resp.events[i].event_id) !== eventId) {
                                relatedEvents.push(resp.events[i]);
                            }
                        }

                        if (contextEvent) {
                            var orgToEvents: any = {}; //hash to track which orgs contain which events in the context and related events
                            if (!contextEvent.bill_item) {
                                contextEvent.bill_item = [];
                            }
                            if (!contextEvent.organization) {
                                contextEvent.organization = [];
                            }

                            jSith.forEach(contextEvent.bill_item, function (_, item) {
                                item.eventId = parseInt(contextEvent.event_id);
                                item.eventLocator = contextEvent.event_locator;
                            });

                            jSith.forEach(contextEvent.organization, function (_, org) {
                                //context orgs to context event
                                var orgId = parseInt(org.organization_id);
                                var eventId = parseInt(contextEvent.event_id);
                                if (!orgToEvents[orgId]) {
                                    orgToEvents[orgId] = {};
                                    orgToEvents[orgId][eventId] = true;
                                } else {
                                    orgToEvents[orgId][eventId] = true;
                                }
                            });

                            for (i = 0; i < relatedEvents.length; i++) {
                                var relatedEvent = relatedEvents[i];

                                relatedEvent.bill_item = relatedEvent.bill_item || [];
                                jSith.forEach(relatedEvent.bill_item, function (_, item) {
                                    item.eventId = parseInt(relatedEvent.event_id);
                                    item.eventLocator = relatedEvent.event_locator;
                                });
                                contextEvent.bill_item = contextEvent.bill_item.concat(relatedEvent.bill_item);

                                relatedEvent.organization = relatedEvent.organization || [];
                                jSith.forEach(relatedEvent.organization, function (_, org) {
                                    //related event org to related event
                                    var orgId = parseInt(org.organization_id);
                                    var eventId = parseInt(relatedEvent.event_id);
                                    if (!orgToEvents[orgId]) {
                                        orgToEvents[orgId] = {};
                                        orgToEvents[orgId][eventId] = true;
                                    } else {
                                        orgToEvents[orgId][eventId] = true;
                                    }
                                });
                                contextEvent.organization = contextEvent.organization.concat(relatedEvent.organization);
                            }
                            contextEvent.organization = S25Util.array.uniqueByProp(
                                contextEvent.organization,
                                "organization_id",
                            );
                            contextEvent.orgToEvents = orgToEvents;
                            contextEvent.eventIds = eventIds;
                            contextEvent.eventsNeedingRefresh = resp.eventsNeedingRefresh;
                            return contextEvent;
                        }
                    });
                } else {
                    return S25Util.all({
                        eventData: EventService.getEventInclude(eventId, includes),
                        eventsNeedingRefresh: EventService.getEventsNeedingRefresh([eventId]),
                    }).then(function (resp) {
                        resp.eventData.eventIds = [eventId];
                        resp.eventData.eventsNeedingRefresh = resp.eventsNeedingRefresh;
                        return resp.eventData;
                    });
                }
            });
        } else {
            return S25Util.all({
                eventData: EventService.getEventInclude(eventId, includes),
                eventsNeedingRefresh: EventService.getEventsNeedingRefresh([eventId]),
            }).then(function (resp) {
                resp.eventData.eventIds = [eventId];
                resp.eventData.eventsNeedingRefresh = resp.eventsNeedingRefresh;
                return resp.eventData;
            });
        }
    }

    public static billItemOrgFilter(orgId: any) {
        return function (billItem: any) {
            return parseInt(billItem.charge_to_id) === parseInt(orgId);
        };
    }

    public static getBillItemsByOrg(eventPricingData: any, orgId: any) {
        orgId = parseInt(orgId);
        return (S25Util.propertyGet(eventPricingData, "bill_item") || []).filter(
            PricingService.billItemOrgFilter(orgId),
        );
    }

    public static onlyItemLines(obj: any) {
        return parseInt(obj.bill_item_type_id) > 0;
    }

    public static onlySubtotalAdj(obj: any) {
        return parseInt(obj.bill_item_type_id) === -1 && parseInt(obj.ev_dt_profile_id) === -2;
    }

    public static itemAdjustmentPercToAmt(obj: any) {
        return (parseFloat(obj.adjustment_percent) || 0) * (parseFloat(obj.list_price) || 0);
    }

    public static agg: any = {
        listPrice: function (allBillItems: any) {
            return S25Util.array.sum(allBillItems.filter(PricingService.onlyItemLines), "list_price");
        },
        adjustments: {
            subtotal: function (allBillItems: any) {
                //how much was adjusted out of list price (eg, discount)
                return (
                    S25Util.array.sum(allBillItems.filter(PricingService.onlyItemLines), "adjustment_amt") +
                    S25Util.array.sum(
                        allBillItems.filter(PricingService.onlyItemLines).map(PricingService.itemAdjustmentPercToAmt),
                    )
                );
            },
            total: function (allBillItems: any) {
                return (
                    PricingService.agg.adjustments.subtotal(allBillItems) +
                    S25Util.array.sum(allBillItems.filter(PricingService.onlySubtotalAdj), "total_charge")
                );
            },
        },
        adjustedPrice: {
            subtotal: function (allBillItems: any) {
                return (
                    PricingService.agg.adjustments.subtotal(allBillItems) + PricingService.agg.listPrice(allBillItems)
                );
            },
            total: function (allBillItems: any) {
                return PricingService.agg.adjustments.total(allBillItems) + PricingService.agg.listPrice(allBillItems);
            },
        },
        taxes: {
            subtotal: function (allBillItems: any): number {
                return S25Util.array.sum(allBillItems.filter(PricingService.onlyItemLines), "total_tax");
            },
            total: function (allBillItems: any) {
                return (
                    PricingService.agg.taxes.subtotal(allBillItems) +
                    S25Util.array.sum(allBillItems.filter(PricingService.onlySubtotalAdj), "total_tax")
                );
            },
            totalByTaxId: function (allBillItems: any, taxesMap: any) {
                let taxIds = S25Util.propertyGetAllUnique(allBillItems, "tax_id");
                return taxIds.map(function (taxId: any) {
                    taxId = parseInt(taxId);
                    let taxName = taxesMap[taxId];
                    let taxBillItems: any[] = [];

                    jSith.forEach(allBillItems, function (_, billItem) {
                        jSith.forEach(billItem.tax, function (_, tax) {
                            if (parseInt(tax.tax_id) === taxId) {
                                let taxItem: any = {};
                                taxItem = S25Util.copy(taxItem, billItem);
                                taxName = tax.tax_name || taxName;
                                taxItem.total_tax = parseFloat(tax.tax_charge || 0);
                                taxBillItems.push(taxItem);
                            }
                        });
                    });

                    let totalTaxCharge = PricingService.agg.taxes.total(taxBillItems);
                    return {
                        tax_id: taxId,
                        tax_charge: totalTaxCharge,
                        itemValue: FormatService.toDollars(totalTaxCharge),
                        itemName: taxName,
                    };
                });
            },
        },
        totalCharge: {
            subtotal: function (allBillItems: any) {
                return (
                    PricingService.agg.adjustedPrice.subtotal(allBillItems) +
                    PricingService.agg.taxes.subtotal(allBillItems)
                );
            },
            total: function (allBillItems: any) {
                return (
                    PricingService.agg.adjustedPrice.total(allBillItems) + PricingService.agg.taxes.total(allBillItems)
                );
            },
        },
    };

    public static getSubTotalObject(org: any, billItems: any) {
        return {
            charge_to_name: org.organization_name,
            charge_to_id: org.organization_id,
            list_price: PricingService.agg.listPrice(billItems),
            adjustment_amt: PricingService.agg.adjustments.subtotal(billItems),
            taxable_amt: PricingService.agg.adjustedPrice.subtotal(billItems),
            total_tax: PricingService.agg.taxes.subtotal(billItems),
            total_charge: PricingService.agg.totalCharge.subtotal(billItems),
        };
    }

    //take event data, and other data, and form model used by s25-list
    public static getPricingOrganizationListModelData(
        eventPricingData: any,
        eventId: number,
        orgId: number,
        rateGroups: any,
        taxesMap: any,
        canEditPricing: boolean,
    ) {
        var eventData: any = {};
        eventData = S25Util.copy(eventData, eventPricingData); //copy of data used for puts
        delete eventData.orgToEvents; //remove orgToEvents from eventData since it is not needed for puts

        var allOrgs = S25Util.propertyGet(eventPricingData, "organization") || [];
        allOrgs = allOrgs.filter(function (org: any) {
            var orgId = parseInt(org.organization_id);
            return (
                !eventPricingData.orgToEvents ||
                (eventPricingData.orgToEvents[orgId] && eventPricingData.orgToEvents[orgId][eventId])
            );
        });

        var ret: any = {
            canEditPricing: canEditPricing,
            canEdit: true,
            canAdjust: true,
            orgId: orgId,
            eventId: eventId,
            evBillId: eventPricingData.evBillId,
            rateGroups: rateGroups,
            taxesMap: taxesMap,
            allOrgs: allOrgs,
            rows: [],
            allBillItems: [],
            eventData: eventData, //used for puts
        };

        jSith.forEach(PricingService.getBillItemsByOrg(eventPricingData, orgId), function (_, billItem) {
            S25Util.merge(billItem, {
                canEdit: ret.canEdit,
                canEditPricing: ret.canEditPricing,
                canAdjust: ret.canAdjust,
                eventId: billItem.eventId || ret.eventId,
                evBillId: billItem.evBillId || ret.evBillId,
                eventLocator: billItem.eventLocator || eventPricingData.event_locator,
            });

            if (billItem.bill_item_id > 0 && billItem.bill_item_type_id > 0) {
                ret.rows.push(billItem);
            }
            ret.allBillItems.push(billItem);
        });
        return ret;
    }

    public static getPricingOccurrenceList(listData: any) {
        let ret: any = [];
        const resMap: any = {};

        let newRows = listData.rows.filter((row: LineItemI) => {
            row.occurrences?.forEach((occ: PricingOccurrence, index: number) => {
                const {
                    bill_item_id,
                    bill_item_name,
                    bill_item_type_id,
                    canAdjust,
                    canEdit,
                    canEditPricing,
                    evBillId,
                    charge_to_id,
                    credit_account_number,
                    debit_account_number,
                    eventId,
                    eventLocator,
                    ev_dt_profile_id,
                    rate_group_name,
                    rate_id,
                    rate_name,
                } = row;

                occ = {
                    ...occ,
                    adjustment_amt: occ.adjustmentAmt,
                    adjustment_name: occ.adjustmentName,
                    adjustment_percent: occ.adjustmentPercent,
                    bill_item_name,
                    bill_item_id,
                    bill_item_type_id,
                    canAdjust,
                    canEdit,
                    canEditPricing,
                    credit_account_number,
                    debit_account_number,
                    evBillId,
                    charge_to_id,
                    eventId,
                    eventLocator,
                    rate_group_name,
                    rate_id,
                    rate_name,
                    allOrgs: listData.allOrgs,
                    rateGroups: listData.rateGroups,
                    profileId: ev_dt_profile_id,
                    eventData: listData.eventData,
                };
                if (!resMap[occ.rsrvId]) {
                    resMap[occ.rsrvId] = {
                        occurrence: occ.occurrence,
                        occView: !!listData.occView,
                        numOccurrences: row.occurrences.length,
                        listItems: [occ],
                        subtotal: listData.occSubtotals[occ.rsrvId],
                        list_price: listData.occSubtotals[occ.rsrvId]?.occurrenceListPrice,
                        charge_to_id: charge_to_id,
                        bill_item_id: bill_item_id,
                        eventLocator: eventLocator,
                        eventData: listData.eventData,
                        taxable_amt: listData.occSubtotals[occ.rsrvId]?.occurrenceTotalCharge,
                        rsrvId: occ.rsrvId,
                    };
                } else {
                    resMap[occ.rsrvId].listItems.push(occ);
                }
            });

            return !row.occurrences || row.occurrences?.length === 0;
        });

        const occurrences: any = Object.values(resMap).sort(
            (a: PricingOccurrence, b: PricingOccurrence) => +new Date(a.occurrence) - +new Date(b.occurrence),
        );

        PricingService.formatOccDates(occurrences, listData.allOccurrences);

        listData.noOccItems = [...newRows];

        newRows = [...occurrences, ...newRows];

        newRows.forEach((row: LineItemI) => {
            const taxesObj: any = { tax: [] };
            let taxes = !row.occurrence && S25Util.propertyGet(row, "tax");
            jSith.forEach(taxes, (_, tax) => {
                taxesObj.tax.push({
                    itemName: tax.tax_name ?? listData.taxesMap[tax.tax_id],
                    itemValue: FormatService.toDollars(tax.tax_charge),
                });
            });

            const itemData = {
                itemName:
                    row.occurrence ??
                    (row.bill_item_name || "") +
                        (row.bill_profile_name && S25Util.profileName(row.bill_profile_name.toString())
                            ? " (" + S25Util.profileName(row.bill_profile_name.toString()) + ")"
                            : ""),
                reservations: row.listItems,
                charge_to_id: row.charge_to_id,
                bill_item_id: row.bill_item_id,
                adjustment:
                    parseFloat(row.adjustment_amt || 0) + parseFloat(row.adjustment_percent || 0) * row.taxable_amt,
                total_tax: row.total_tax,
                total_charge: row.total_charge,
                dateFormat: listData.dateFormat,
                noOccItems: listData.noOccItems,
            };

            const identifier = row.bill_item_type_id === 1 ? "eventType" : row.rsrvId ?? row.bill_item_id;
            const setNames = !!listData.idToBillName[identifier]
                ? Array.from(listData.idToBillName[identifier]).join(", ")
                : "";

            let tableRow = [
                itemData,
                row.list_price ?? "0",
                Object.assign({}, row, {
                    adjustmentType: 1,
                    itemName: parseFloat(row.adjustment_amt || 0) + parseFloat(row.adjustment_percent || 0),
                    eventData: listData.eventData,
                    occView: !!listData.occView,
                    noOccItems: listData.noOccItems,
                }), //itemName used for sorting
                row.taxable_amt ?? "0",
                taxesObj,
                row.total_charge ?? row.taxable_amt ?? "0",
                S25Util.merge({ eventData: listData.eventData }, row, {
                    allOrgs: listData.allOrgs,
                }),
                row.rate_name ?? "",
                S25Util.merge({ eventData: listData.eventData }, row, {
                    rateGroups: listData.rateGroups,
                    itemName: row.rate_group_name,
                    fls: listData.fls,
                }),
                row.debit_account_number ?? "",
                row.credit_account_number ?? "",
            ];

            listData.combineRelatedEvents && tableRow.unshift(row.eventLocator);
            listData.occView && !listData.evBillId && tableRow.splice(6, 0, setNames);

            ret.push({
                itemName: itemData.itemName, //used for initial sort
                row: tableRow,
            });
        });

        if (ret.length === 0) {
            //at least one row (an empty one) so that list still shows since footer may have data
            ret.push({ row: ["(none)", "0", "", "0", "", "0", "", "", "", "", ""] });
        }

        return ret;
    }

    //take model supplied by getPricingOrganizationListModelData and form general s25 styled list (headers, body, footer) to simulate a pricing list for a single org
    //(the output is a "getdata" function for a list)
    public static getPricingOrganizationListFn(orgModelData: any) {
        // var colsArray: any = [];
        // colsArray = S25Util.copy(colsArray, PricingService.PricingConst.cols);
        // !orgModelData.combineRelatedEvents && S25Util.array.inplaceRemoveByProp(colsArray, "prefname", "reference");
        var rowsF = function (listData: any) {
            if (orgModelData.hideNoChargeItems) {
                if (listData.isOccurrence) {
                    listData.reservations = listData.reservations.filter((row: LineItemI) => row.total);
                } else {
                    listData.rows = listData.rows.filter((row: LineItemI) => row.total_charge);
                }
            }
            //function ran by the list generator to create list rows from input data
            var ret: any[] = [];
            if (listData) {
                var eventIds = S25Util.propertyGetAllUnique(listData.allBillItems, "eventId") || [];
                jSith.forEach(listData.rows ?? listData.reservations, function (i, obj) {
                    //set data rows
                    var taxesObj: any = { tax: [], templateType: 15 };
                    let taxes = (!obj.occurrence && S25Util.propertyGet(obj, "tax")) || [];
                    jSith.forEach(taxes, function (_, tax) {
                        taxesObj.tax.push({
                            itemName: tax.tax_name || listData.taxesMap[tax.tax_id],
                            itemValue: FormatService.toDollars(tax.tax_charge),
                        });
                    });

                    var itemData = {
                        itemName:
                            (obj.bill_item_name || "") +
                            (obj.bill_profile_name && S25Util.profileName(obj.bill_profile_name.toString())
                                ? " (" + S25Util.profileName(obj.bill_profile_name.toString()) + ")"
                                : ""),
                        occurrences: obj.occurrences,
                        charge_to_id: obj.charge_to_id,
                        bill_item_id: obj.bill_item_id,
                        adjustment:
                            parseFloat(obj.adjustment_amt || 0) +
                            parseFloat(obj.adjustment_percent || 0) * obj.taxable_amt,
                        total_tax: obj.total_tax,
                        total_charge: obj.total_charge ?? obj.total,
                        dateFormat: listData.dateFormat,
                        combineRelatedEvents: listData.combineRelatedEvents,
                    };
                    var row = [];
                    orgModelData.combineRelatedEvents && row.push(obj.eventLocator);
                    row = row.concat([
                        itemData,
                        obj.list_price ?? obj.listPrice ?? "0",
                        Object.assign({}, obj, {
                            eventData: listData.eventData ?? obj.eventData,
                            eventIds: eventIds,
                            combineRelatedEvents: listData.combineRelatedEvents,
                            adjustmentType: listData.rows ? 1 : 2, //type1 for line item adjustment, type2 for reservation adjustment
                            itemName: parseFloat(obj.adjustment_amt || 0) + parseFloat(obj.adjustment_percent || 0),
                            occView: !!listData.occView,
                            allOccurrences: listData.allOccurrences,
                            isOccurrence: listData.isOccurrence,
                            noOccItems: listData.noOccItems,
                        }), //itemName used for sorting
                        obj.taxable_amt ?? obj.price ?? "0",
                        taxesObj,
                        obj.total_charge ?? obj.total ?? "0",
                        Object.assign({ eventData: listData.eventData ?? obj.eventData }, obj, {
                            templateType: 13,
                            allOrgs: listData.allOrgs ?? obj.allOrgs,
                            isOccurrence: listData.isOccurrence,
                        }),
                        obj.rate_name,
                        Object.assign({ eventData: listData.eventData ?? obj.eventData }, obj, {
                            templateType: 14,
                            rateGroups: listData.rateGroups ?? obj.rateGroups,
                            itemName: obj.rate_group_name,
                            fls: listData.fls,
                            isOccurrence: listData.isOccurrence,
                        }),
                        obj.debit_account_number,
                        obj.credit_account_number,
                    ]);

                    listData.occView && !obj.evBillId && row.splice(6, 0, "");

                    ret.push({
                        itemName: itemData.itemName, //used for initial sort
                        row: row,
                    });
                });
            }
            ret.sort(S25Util.shallowSort("itemName")); //initial sort by bill item name
            if (ret.length === 0) {
                //at least one row (an empty one) so that list still shows since footer may have data
                ret.push({ row: ["(none)", "0", "", "0", "", "0", "", "", "", "", ""] });
            }
            return ret;
        };

        //list footer
        var footerF = function (listData: any) {
            const occSubtotals = Object.values(listData.occSubtotals);
            const taxTotal = PricingService.agg.taxes.subtotal(
                listData.occView ? listData.noOccItems : listData.allBillItems,
            );

            //same as rowsF but for the footer of the list (an optional component in general)
            var ret;
            if (listData && listData.allBillItems.length > 0) {
                var eventIds = S25Util.propertyGetAllUnique(listData.allBillItems, "eventId") || [];
                //adjustments
                var adj = new Array(eventIds.length);
                for (var i = 0; i < adj.length; i++) {
                    var eventId = eventIds[i];
                    var eventBillItems = S25Util.propertyGetParentsWithChildValue(
                        listData.allBillItems,
                        "eventId",
                        eventId,
                    );
                    var eventLocator = eventBillItems && eventBillItems[0] && eventBillItems[0].eventLocator;
                    var adjRow = [];
                    orgModelData.combineRelatedEvents && adjRow.push(eventLocator);
                    adjRow = adjRow.concat([
                        listData.occView ? "Profile Entries:" : "Adjustments:",

                        listData.occView
                            ? {
                                  type: "listPrice",
                                  value: PricingService.formatCurrency(
                                      occSubtotals.reduce(
                                          (sum: number, item: AccountOccurrenceSubtotal) =>
                                              sum + item.occurrenceAdjustments,
                                          0,
                                      ),
                                  ),
                              }
                            : "",

                        //subtotal adjustments (plus add NEW adjustment)
                        {
                            allBillItems: eventBillItems,
                            eventIds: eventIds ?? eventId,
                            eventData: listData.eventData,
                            eventLocator: eventLocator,
                            occView: !!listData.occView,
                            noOccItems: listData.noOccItems,
                            profileSubtotals: listData.profileSubtotals,
                            canAdjust: listData.canAdjust,
                            canEdit: listData.canEdit,
                            canEditPricing: listData.canEditPricing,
                            eventId: eventId,
                            evBillId: listData.evBillId,
                            orgId: listData.orgId,
                            combineRelatedEvents: listData.combineRelatedEvents,
                        }, //can*, has* needed for perms while editing in the pricing modal

                        "",
                        listData.occView
                            ? {
                                  type: "tax",
                                  value: PricingService.formatCurrency(
                                      PricingService.agg.taxes.subtotal(listData.allBillItems) - taxTotal,
                                  ),
                              }
                            : "",

                        //sub total charges
                        {
                            allBillItems: eventBillItems,
                            evBillId: listData.evBillId,
                            orgId: listData.orgId,
                            occView: !!listData.occView,
                            noOccItems: listData.noOccItems,
                            profileSubtotals: listData.profileSubtotals,
                            profileTax: listData.occView
                                ? PricingService.formatCurrency(
                                      PricingService.agg.taxes.subtotal(listData.allBillItems) - taxTotal,
                                  )
                                : null,
                        },

                        "",
                        "",
                        "",
                        "",
                        "",
                    ]);
                    adj[i] = { row: adjRow };
                }

                ret = [];

                //subtotals
                var subRow = [];
                orgModelData.combineRelatedEvents && subRow.push("");

                subRow = subRow.concat([
                    "Subtotal",
                    "" +
                        (PricingService.agg.listPrice(listData.occView ? listData.noOccItems : listData.allBillItems) +
                            (listData.occView
                                ? occSubtotals.reduce(
                                      (sum: number, item: AccountOccurrenceSubtotal) => sum + item.occurrenceListPrice,
                                      0,
                                  )
                                : 0)), //sticker price
                    //list price is the sticker price and taxable amt is the before-tax price to the customer. The difference is thus the total adjustment
                    //note: we could filter bill_item_type_id > 0 but negative ones (totals/sub-total rows) dont have list_price or taxable_amt so we need not filter
                    FormatService.toDollars(
                        PricingService.agg.adjustments.subtotal(
                            listData.occView ? listData.noOccItems : listData.allBillItems,
                        ) +
                            (listData.occView
                                ? occSubtotals.reduce(
                                      (sum: number, item: AccountOccurrenceSubtotal) =>
                                          sum + item.occurrenceAdjustments,
                                      0,
                                  )
                                : 0),
                    ), //adj col
                    "" +
                        (PricingService.agg.adjustedPrice.subtotal(
                            listData.occView ? listData.noOccItems : listData.allBillItems,
                        ) +
                            (listData.occView
                                ? occSubtotals.reduce(
                                      (sum: number, item: AccountOccurrenceSubtotal) =>
                                          sum + item.occurrenceTotalCharge,
                                      0,
                                  )
                                : 0)), //Price col
                    FormatService.toDollars(taxTotal), //Taxes col
                    "" +
                        (PricingService.agg.totalCharge.subtotal(
                            listData.occView ? listData.noOccItems : listData.allBillItems,
                        ) +
                            (listData.occView
                                ? occSubtotals.reduce(
                                      (sum: number, item: AccountOccurrenceSubtotal) =>
                                          sum + item.occurrenceTotalCharge,
                                      0,
                                  )
                                : 0)), //Total col,
                    "",
                    "",
                    "",
                    "",
                    "",
                ]);

                ret.push({ row: subRow });

                //adjustments
                ret = ret.concat(adj);

                //totals
                var totalRow = [];
                orgModelData.combineRelatedEvents && totalRow.push("");
                totalRow = totalRow.concat([
                    "Total",
                    "" + PricingService.agg.listPrice(listData.allBillItems), //sticker price
                    FormatService.toDollars(PricingService.agg.adjustments.total(listData.allBillItems)), //adj
                    "" + PricingService.agg.adjustedPrice.total(listData.allBillItems), //price
                    FormatService.toDollars(PricingService.agg.taxes.total(listData.allBillItems)), //taxes
                    "" + PricingService.agg.totalCharge.total(listData.allBillItems), //total
                    listData.paymentData ?? "",
                    "",
                    "",
                    "",
                    "",
                ]);
                ret.push({ row: totalRow });
            }

            return ret;
        };
        return {
            getRows: rowsF,
            getFooter: footerF,
        };
        // return ListGeneratorService.s25Generate(
        //     null,
        //     colsArray,
        //     rowsF,
        //     function () {
        //         return jSith.when(orgModelData);
        //     },
        //     footerF
        // );
    }

    public static formatOccDates(occs: any, occData: any) {
        const occNames = occs.map((occ: any) => occ.occurrence);
        const noDupOccNames = new Set(occNames);

        occs.map((occ: any) => {
            if (
                occNames.length === noDupOccNames.size &&
                S25Datefilter.transform(occData[occ.rsrvId].rsrvStartDt, occData.dateFormat) ===
                    S25Datefilter.transform(occData[occ.rsrvId].rsrvEndDt, occData.dateFormat)
            ) {
                // all occurrence dates different - no need to specify times
                occ.occurrence = S25Datefilter.transform(occ.occurrence, occData.dateFormat);
            } else if (
                S25Datefilter.transform(occData[occ.rsrvId].rsrvStartDt, occData.dateFormat) !==
                S25Datefilter.transform(occData[occ.rsrvId].rsrvEndDt, occData.dateFormat)
            ) {
                // multi-day occurrences
                occ.occurrence = `${S25Datefilter.transform(
                    occData[occ.rsrvId].rsrvStartDt,
                    occData.dateFormat,
                )} - ${S25Datefilter.transform(occData[occ.rsrvId].rsrvEndDt, occData.dateFormat)}`;
            } else {
                // same day - different times occurrences
                occ.occurrence = `${S25Datefilter.transform(
                    occData[occ.rsrvId].rsrvStartDt,
                    occData.dateFormat,
                )} ${S25Datefilter.transform(
                    occData[occ.rsrvId].rsrvStartDt,
                    occData.timeFormat,
                )} - ${S25Datefilter.transform(occData[occ.rsrvId].rsrvEndDt, occData.timeFormat)}`;
            }

            return occ;
        });
    }
    //take model data supplied by caller and form the pricing totals list (the output is a "getdata" function for a list)
    public static getPricingTotalsListFn(modelData: any) {
        var colsArray = [
            { name: "Item", prefname: "item", sortable: 0, isDefaultVisible: 1 },
            { name: "List Price", prefname: "list_price", sortable: 0, isDefaultVisible: 1 },
            { name: "Adjustments", prefname: "adjustments", sortable: 0, isDefaultVisible: 1 },
            { name: "Price", prefname: "price", sortable: 0, isDefaultVisible: 1 },
            { name: "Taxes", prefname: "taxes", sortable: 0, isDefaultVisible: 1 },
            { name: "Total", prefname: "total", sortable: 0, isDefaultVisible: 1, isMoney: 1 },
            { name: "Charge To", prefname: "charge_to", sortable: 0, isDefaultVisible: 1 },
        ];
        var rowsF = function (listData: any) {
            var ret: any[] = [];
            if (listData) {
                jSith.forEach(listData.rows, function (_, obj) {
                    //set data rows
                    ret.push({
                        row: [
                            !obj.isAdjustment ? "Subtotals: " : "Adjustment: " + (obj.adjustment_name || ""),
                            !obj.isAdjustment ? FormatService.toDollars(obj.list_price) : "",
                            !obj.isAdjustment
                                ? FormatService.toDollars(obj.adjustment_amt)
                                : !isNaN(parseFloat(obj.adjustment_amt))
                                  ? FormatService.toDollars(obj.adjustment_amt)
                                  : !isNaN(parseFloat(obj.adjustment_percent))
                                    ? FormatService.formatPercent(obj.adjustment_percent)
                                    : "",
                            !obj.isAdjustment ? FormatService.toDollars(obj.taxable_amt) : "",
                            !obj.isAdjustment ? FormatService.toDollars(obj.total_tax) : "",
                            obj.total_charge || "0",
                            obj.charge_to_name,
                        ],
                    });
                });
            }
            ret.sort(S25Util.shallowSort("itemName"));
            return ret;
        };

        //list footer
        var footerF = function (listData: any) {
            var ret;
            if (listData && listData.rows.length > 0) {
                ret = [
                    //grand total
                    {
                        row: [
                            "Grand Total",
                            FormatService.toDollars(listData.grandListPrice),
                            FormatService.toDollars(listData.grandAdjustmentAmt),
                            FormatService.toDollars(listData.grandTaxableAmt || "0"),
                            S25Util.merge(listData.grandTaxes, {
                                templateType: 15,
                                evBillId: listData.evBillId,
                            }),
                            listData.grandTotalCharge || "0",
                            "",
                        ],
                    },
                ];
            }
            return ret;
        };
        return ListGeneratorService.s25Generate(
            null,
            colsArray,
            rowsF,
            function () {
                return jSith.when(modelData);
            },
            footerF,
        );
    }

    //put a sub total pricing item to an event (these items are located in the footer of an org list)
    public static putPricingSubtotalItem(
        eventData: any,
        orgId: number,
        isNew: boolean,
        adjustmentValue: any,
        adjustmentType: any,
        objId: number,
        eventId: number,
        reason: string,
        evBillId: number,
    ) {
        var op = isNew ? "add" : "update";
        return PricingService.putPricingLineItem(
            eventData,
            {
                bill_item_id: isNew ? null : objId,
            },
            null,
            null,
            adjustmentValue,
            adjustmentType,
            {
                organization_id: orgId,
            },
            eventId,
            reason,
            op,
            true,
            evBillId,
        );
    }

    //put pricing line item to event (these are located in an org list's body)
    @Timeout
    public static putPricingLineItem(
        eventData: any,
        billItem: any,
        rateGroupId: number,
        rateScheduleId: number,
        adjustmentValue: string,
        adjustmentType: any,
        orgObj: any,
        eventId: number,
        reason: any,
        opOverride: any,
        isAdjustment: true,
        evBillId: number,
    ) {
        var billItemId = billItem.bill_item_id;
        var billItemTypeId = billItem.bill_item_type_id;
        var profileId = billItem.ev_dt_profile_id;
        var chargeToId = orgObj.organization_id > 0 ? orgObj.organization_id : null;
        var adjustmentName = reason;
        rateGroupId = rateGroupId > 0 ? rateGroupId : null;
        var rateId = rateScheduleId > 0 ? rateScheduleId : null;

        var adjustmentPercent,
            adjustmentAmt,
            op = opOverride;

        //ANG-4959 force delete of existing GLOBAL adjustments when there's no comment and the value is 0
        if (isAdjustment && !parseFloat(adjustmentValue) && !reason && !!billItemId) {
            return PricingService.deletePricingAdjustment(eventId, evBillId, billItemId);
        }

        if (adjustmentType === "percentage") {
            adjustmentPercent = adjustmentValue;
        } else {
            adjustmentAmt = adjustmentValue;
        }

        var billItemArr = S25Util.propertyGet(eventData, "bill_item") || [];
        if (!op && billItemArr.length > 0) {
            //actually replace billItem with the newBillItem
            op = "update";
        } else if (!op) {
            op = "add";
        }

        let payload: any = {
            content: {
                data: [
                    {
                        op: op,
                        lineItems: [
                            {
                                profileId: profileId,
                                itemId: billItemId,
                                itemType: billItemTypeId,
                            },
                        ],
                    },
                ],
            },
        };

        let lineItem = payload.content.data[0].lineItems[0];
        S25Util.extend(lineItem, {
            chargeToId: chargeToId,
            adjustmentName: adjustmentName,
            rateGroupId: rateGroupId,
            rateId: rateId,
            adjustmentAmt: parseFloat(adjustmentAmt),
            adjustmentPct: parseFloat(adjustmentPercent),
        });

        if (isAdjustment) {
            payload.content.data[0].adjustments = payload.content.data[0].lineItems;
            payload.content.data[0].adjustments[0].billId = evBillId;
            delete payload.content.data[0].lineItems;
        } else {
            payload.content.data[0].lineItems[0].billId = evBillId;
        }

        evBillId && payload.content.data.push({ billIdList: [evBillId] });

        payload = S25Util.deleteUndefDeep(payload);

        if (evBillId) {
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/billingCustom.json?include=occurrences&expand=T",
                    "PricingService.putPricingLineItem",
                ),
                payload,
            );
        } else {
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/" + eventId + "/billing.json?include=occurrences&expand=T",
                    "PricingService.putPricingLineItem",
                ),
                payload,
            );
        }
    }

    // Delete a subtotal adjustment
    @Timeout
    public static deletePricingAdjustment(eventId: number, evBillId: number, adjustmentId: number) {
        if (evBillId) {
            let payload = {
                content: {
                    data: [
                        { op: "remove", adjustments: [{ itemId: adjustmentId, billId: evBillId }] },
                        { billIdList: [evBillId] },
                    ],
                },
            };
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/billingCustom.json?include=occurrences&expand=T",
                    "PricingService.deletePricingAdjustment",
                ),
                payload,
            );
        }

        let payload = { content: { data: [{ op: "remove", adjustments: [{ itemId: adjustmentId }] }] } };
        return DataAccess.put(
            DataAccess.injectCaller(
                `/micro/event/${eventId}/billing.json?include=occurrences&expand=T`,
                "PricingService.deletePricingAdjustment",
            ),
            payload,
        );
    }

    public static putPricingLineItemAttr(
        eventId: number,
        attrName: string,
        attrValue: any,
        billItemId: number,
        billItemTypeId: number,
        profileId: number,
        evBillId: number,
    ) {
        let payload: any = {
            content: {
                data: [
                    {
                        op: "update",
                        lineItems: [{ profileId: profileId, itemId: billItemId, itemType: billItemTypeId }],
                    },
                ],
            },
        };
        payload.content.data[0].lineItems[0][attrName] = attrValue;

        if (evBillId) {
            payload.content.data[0].lineItems[0].billId = evBillId;
            payload.content.data.push({ billIdList: [evBillId] });
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/billingCustom.json?include=occurrences&expand=T",
                    "PricingService.putPricingLineItemAttr",
                ),
                payload,
            );
        } else {
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/" + eventId + "/billing.json?include=occurrences&expand=T",
                    "PricingService.putPricingLineItemAttr",
                ),
                payload,
            );
        }
    }

    public static putPricingLineItemChargeToId(
        eventId: number,
        chargeToId: number,
        billItemId: number,
        billItemTypeId: number,
        profileId: number,
        evBillId: number,
    ) {
        return PricingService.putPricingLineItemAttr(
            eventId,
            "chargeToId",
            chargeToId,
            billItemId,
            billItemTypeId,
            profileId,
            evBillId,
        );
    }

    public static putPricingLineItemRateGroup(
        eventId: number,
        rateGroupId: number,
        billItemId: number,
        billItemTypeId: number,
        profileId: number,
        evBillId: number,
    ) {
        return PricingService.putPricingLineItemAttr(
            eventId,
            "rateGroupId",
            rateGroupId,
            billItemId,
            billItemTypeId,
            profileId,
            evBillId,
        );
    }

    public static putPricingReservationItem(
        eventId: number,
        profileId: number,
        rsrvId: number,
        billItemId: number,
        billItemType: number,
        adjustmentType: "dollarAmt" | "percentage",
        adjustmentValue: number,
        description: string,
        evBillId: number,
        isNew: boolean,
    ) {
        let payload: any = {
            content: {
                data: [
                    {
                        op: isNew ? "add" : "update",
                        lineItems: [
                            {
                                profileId: profileId,
                                rsrvId: rsrvId,
                                itemId: billItemId,
                                itemType: billItemType,
                                adjustmentName: description,
                            },
                        ],
                    },
                ],
            },
        };

        if (adjustmentType === "percentage") {
            payload.content.data[0].lineItems[0].adjustmentPct = adjustmentValue;
        } else {
            payload.content.data[0].lineItems[0].adjustmentAmt = adjustmentValue;
        }

        if (evBillId) {
            payload.content.data[0].lineItems[0].billId = evBillId;
            payload.content.data.push({ billIdList: [evBillId] });
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/billingCustom.json?include=occurrences&expand=T",
                    "PricingService.putPricingReservationItem",
                ),
                payload,
            );
        } else {
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/" + eventId + "/billing.json?include=occurrences&expand=T",
                    "PricingService.putPricingReservationItem",
                ),
                payload,
            );
        }
    }
    public static putPricingEventRateGroup(eventId: number, rateGroupId: number, evBillId: number) {
        let payload: any = { content: { data: [{ op: "all", fields: [{ rateGroupId: rateGroupId }] }] } };

        if (evBillId) {
            payload.content.data[0].fields[0].billId = evBillId;
            payload.content.data.push({ billIdList: [evBillId] });
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/billingCustom.json?include=occurrences&expand=T",
                    "PricingService.putPricingEventRateGroup",
                ),
                payload,
            );
        } else {
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/" + eventId + "/billing.json?include=occurrences&expand=T",
                    "PricingService.putPricingEventRateGroup",
                ),
                payload,
            );
        }
    }

    //put modified pricing bill date to event (located in the summary block at the top of the pricing component)
    public static putPricingBillingDate(eventId: number, newDate: Date, evBillId: number) {
        let payload: any = {
            content: {
                data: [
                    {
                        op: "all",
                        fields: [{ billDate: S25Util.date.toS25ISODateStrEndOfDay(S25Util.date.getDate(newDate)) }],
                    },
                ],
            },
        };

        if (evBillId) {
            payload.content.data[0].fields[0].billId = evBillId;
            payload.content.data.push({ billIdList: [evBillId] });
            return DataAccess.put(
                DataAccess.injectCaller("/micro/event/billingCustom.json", "PricingService.putPricingBillingDate"),
                payload,
            );
        } else {
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/" + eventId + "/billing.json",
                    "PricingService.putPricingBillingDate",
                ),
                payload,
            );
        }
    }

    //take pricing data and form invoice model used for freshbooks integration, eg form "invoicesByEvent" json
    //@eventData: data from service call: PricingService.getPricingRelatedEvents
    //@orgId: org being billed for invoice
    public static formInvoiceModelFromPricing(eventData: any, orgId: number) {
        let invoicesByEvent: any = {
            events: [],
            totalOrgCharge: 0,
        };

        //sort events by start dt
        eventData && eventData.sort(S25Util.shallowSort("start_date"));

        //populate events and compute overall total charge (for this org)
        jSith.forEach(eventData, function (_, e) {
            //for each event
            let orgBillingItems = PricingService.getBillItemsByOrg(e, orgId);
            let orgTotalCharge = PricingService.agg.totalCharge.total(orgBillingItems);
            if (orgTotalCharge !== 0) {
                invoicesByEvent.totalOrgCharge += orgTotalCharge; //keep track of total charge across events in org

                //form indiv event info with org billing info
                let event: any = {
                    eventName: e.event_name,
                    eventLocator: e.event_locator,
                    eventId: e.event_id,
                    eventStartDt: e.start_date,
                    eventEndDt: e.end_date,
                    profile: [],
                    requirements: [],
                    orgAdjustments: [],
                };

                //sort profiles by init_start_dt
                e.profile = e.profile || [];
                e.profile.sort(S25Util.shallowSort("init_start_dt"));

                //separate billing items into profiles on the event
                //these line items include any line item adjustments as well
                jSith.forEach(e.profile, function (i, p) {
                    let orgProfileBilling = orgBillingItems.filter(function (obj: any) {
                        return parseInt(obj.ev_dt_profile_id) === parseInt(p.profile_id);
                    });
                    orgProfileBilling.sort(S25Util.shallowSort("bill_item_name"));
                    if (orgProfileBilling.length) {
                        event.profile.push({
                            profileName: S25Util.coalesce(
                                S25Util.profileName(p.profile_name.toString()),
                                "Segment " + (i + 1),
                            ),
                            billingItems: orgProfileBilling,
                        });
                    }
                });

                //requirement items on event (exist outside any event profile)
                event.requirements = orgBillingItems
                    .filter(function (billItem: any) {
                        var profileId = parseInt(billItem.ev_dt_profile_id);
                        var totalCharge = parseFloat(billItem.total_charge);
                        var billItemTypeId = parseInt(billItem.bill_item_type_id);
                        var listPrice = parseFloat(billItem.list_price);

                        return (
                            profileId === 0 &&
                            billItemTypeId === 2 &&
                            !isNaN(totalCharge) &&
                            !isNaN(listPrice) &&
                            listPrice !== 0
                        );
                    })
                    .sort(S25Util.shallowSort("bill_item_name"));

                //adjustments on event (exist outside any event profile)
                event.orgAdjustments = orgBillingItems
                    .filter(function (billItem: any) {
                        var profileId = parseInt(billItem.ev_dt_profile_id);
                        var totalCharge = parseFloat(billItem.total_charge);
                        var billItemTypeId = parseInt(billItem.bill_item_type_id);

                        return profileId === -2 && billItemTypeId === -1 && !isNaN(totalCharge);
                    })
                    .sort(S25Util.shallowSort("bill_item_type_name")); //these are sorted by bill item TYPE name

                invoicesByEvent.events.push(event); //append to model array of events
            }
        });

        return invoicesByEvent;
    }

    @Timeout
    public static getStandardPricingForEvent(eventIdArr: number[]) {
        return DataAccess.get<{
            content: { data: { items: Billing[] }; id: number; updated: ISODateString };
        }>(
            DataAccess.injectCaller(
                "/micro/event/" + eventIdArr.join("+") + "/billing.json",
                "PricingService.getStandardPricingForEvent",
            ),
        ).then(function (data) {
            return (data && data.content && data.content.data && data.content.data.items) || [];
        });
    }

    @Timeout
    public static getStandardPricingForEventIncludeRateInfo(eventIdArr: number[]) {
        return DataAccess.get<{ content: MicroBilling }>(
            DataAccess.injectCaller(
                "/micro/event/" +
                    eventIdArr.join("+") +
                    "/billing.json?include=rate_info+organizations+profiles+occurrences&expand=T",
                "PricingService.getStandardPricingForEvent",
            ),
        ).then(function (data) {
            return data.content;
        });
    }

    @Timeout
    public static getPricingSetsForEvents(eventIds: any) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/micro/event/billingCustom.json?eventId=" + eventIds.join("+"),
                "PricingService.getPricingSetsForEvents",
            ),
        ).then(
            function (data) {
                return (data && data.content && data.content.data && data.content.data.items) || [];
            },
            function () {
                return [];
            },
        );
    }

    @Timeout
    public static getPricingSet(evBillId: number) {
        return DataAccess.get<{ content: MicroBilling }>(
            DataAccess.injectCaller(
                `/micro/event/billingCustom.json?billId=${evBillId}&include=rate_info+organizations+profiles+occurrences&expand=T`,
                "PricingService.getPricingSet",
            ),
        ).then(function (data) {
            return data?.content;
        });
    }

    @Timeout
    public static deletePricingSet(evBillId: number) {
        TelemetryService.sendWithSub("Pricing", "Event", "SetDelete");
        return DataAccess.delete(
            DataAccess.injectCaller(
                "/micro/event/" + evBillId + "/billingCustom.json",
                "PricingService.deletePricingSet",
            ),
        );
    }

    @Timeout
    public static postBillingCustom(name: string, eventIds: any, rsrvIds: any, reqIds: any, includeEventType: string) {
        const isRequirementsOnly = reqIds?.length > 0 && !rsrvIds?.length;

        let payload = {
            content: {
                data: [
                    {
                        op: "params",
                        eventIdList: eventIds,
                        rsrvIdList: rsrvIds,
                        includeRequirements: reqIds?.length > 0,
                        includeSpaces: !isRequirementsOnly,
                        includeResources: !isRequirementsOnly,
                        requirementIdList: reqIds,
                        includeEventType: includeEventType,
                        billName: name,
                    },
                ],
            },
        };
        return DataAccess.post(
            DataAccess.injectCaller("/micro/event/billingCustom.json", "PricingService.postBillingCustom"),
            payload,
        );
    }

    public static formatCurrency(data: any) {
        const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        });

        return formatter.format(data);
    }

    public static processTableUpdate(data: any, model: any) {
        const { lineItems, subtotals, totals } = data.content?.data?.items[0]?.billing;
        const adjustments = data.content?.data.items[0]?.billing?.adjustments;
        const occurrences = data.content?.expandedInfo?.occurrences;
        const { canAdjust, canEdit, canEditPricing, evBillId, eventId } = model;

        let occNames: any;
        if (occurrences) {
            occNames = S25Util.fromEntries(occurrences?.map((occ: PricingOccurrence) => [occ.rsrvId, occ.occurrence]));
        }

        const itemsMap: any = {
            subtotals: subtotals[0]?.account,
            adjustments,
            taxData: {},
            combineRelatedEvents: model.combineRelatedEvents,
            // changedItem: null,
        };

        const eventLocatorMap: any = {};
        model.eventData.bill_item.forEach((item: LineItemI) => {
            if (item.eventId && !eventLocatorMap[item.eventId]) {
                eventLocatorMap[item.eventId] = item.eventLocator;
            } else {
                eventLocatorMap[model.eventId] = model.eventLocator;
            }
        });

        lineItems.map((item: any) => {
            const row = {
                adjustment_amt: item.adjustmentAmt,
                adjustment_percent: item.adjustmentPercent / 100,
                adjustment_name: item.adjustmentName,
                canAdjust,
                canEdit,
                canEditPricing,
                charge_to_id: item.chargeToId,
                charge_to_name: model.orgNameMap ? model.orgNameMap[item.chargeToId] : "",
                evBillId,
                originalRateGroupId: model.rate_group_id,
                credit_account_number: item.creditAccountNumber,
                debit_account_number: item.debitAccountNumber,
                bill_item_id: item.itemId,
                bill_item_name: item.itemName,
                bill_item_type_id: item.itemType,
                list_price: item.listPrice ?? 0,
                taxable_amt: item.price,
                eventId,
                noOccItems: model.noOccItems,
                ev_dt_profile_id: item.profileId,
                rate_group_id: item.rateGroupId,
                rate_group_name:
                    model.rateGroups?.find((group: any) => group.rate_group_id === item.rateGroupId)?.rate_group_name ??
                    model.rate_group_name,
                rate_id: item.rateId,
                rate_name: model.rateNameMap ? model.rateNameMap[item.rateId] : model.rate_name,
                total_tax: item.tax,
                deleteId: data.deleteId,
                occurrences: item.occurrences?.map((occ: any) => ({ ...occ, occurrence: occNames[occ.rsrvId] })),
                isNew: data.isNew,
                prevTax: model.tax,
                tax: item.taxes.map((tax: any) => {
                    const taxObj = {
                        tax_charge: tax.taxCharge,
                        tax_id: tax.taxId,
                        tax_name: tax.taxName,
                    };

                    if (itemsMap.taxData[taxObj.tax_id]) {
                        itemsMap.taxData[taxObj.tax_id].tax_charge += taxObj.tax_charge;
                        itemsMap.taxData[taxObj.tax_id].itemValue = PricingService.formatCurrency(
                            itemsMap.taxData[taxObj.tax_id].tax_charge,
                        );
                    } else {
                        itemsMap.taxData[taxObj.tax_id] = {
                            tax_id: taxObj.tax_id,
                            tax_charge: taxObj.tax_charge,
                            itemValue: PricingService.formatCurrency(taxObj.tax_charge),
                            itemName: taxObj.tax_name,
                        };
                    }

                    return taxObj;
                }),
                total_charge: item.total,
            };

            if (itemsMap[item.chargeToId]) {
                itemsMap[item.chargeToId].rows = itemsMap[item.chargeToId].rows.filter(
                    (billItem: any) =>
                        billItem.bill_item_type_id > 0 &&
                        (billItem.bill_item_id !== item.itemId || billItem.ev_dt_profile_id !== item.profileId) &&
                        billItem.charge_to_id === item.chargeToId,
                );
                itemsMap[item.chargeToId].rows.push(row);
            } else {
                itemsMap[item.chargeToId] = {
                    rows: [
                        row,
                        ...model.eventData.bill_item.filter((billItem: any) => {
                            billItem.canAdjust = canAdjust;
                            billItem.canEdit = canEdit;
                            billItem.canEditPricing = canEditPricing;
                            billItem.evBillId = evBillId;

                            return (
                                eventLocatorMap[billItem.eventId] &&
                                (billItem.bill_item_id !== item.itemId ||
                                    billItem.ev_dt_profile_id !== item.profileId) &&
                                billItem.charge_to_id === item.chargeToId &&
                                billItem.bill_item_type_id > 0
                            );
                        }),
                    ],
                    footerData: {},
                };
            }

            return row;
        });

        subtotals[0].accountOccurrence?.map((occ: any) => {
            if (!itemsMap[occ.chargeToId].occSubtotals) {
                itemsMap[occ.chargeToId].occSubtotals = { [occ.rsrvId]: occ };
            } else {
                itemsMap[occ.chargeToId].occSubtotals[occ.rsrvId] = occ;
            }
        });

        let index: number;

        if (model.occView && !model.isOccurrence && !model.allBillItems) {
            // occurrences updated, but non-occurrence line Items have to be updated if adjusted
            let changedItem = model.noOccItems.find((item: LineItemI, i: number) => {
                index = i;
                return item.bill_item_id === model.bill_item_id;
            });
            const newValue = lineItems.find((item: LineItem) => item.itemId === changedItem.bill_item_id);

            changedItem = {
                ...changedItem,
                adjustment_amt: newValue.adjustmentAmt,
                adjustment_percent: newValue.adjustmentPercent,
                adjustment_name: newValue.adjustmentName,
                tax: newValue.taxes,
                taxable_amt: newValue.price,
                list_price: newValue.listPrice,
                total_charge: newValue.total,
            };
            model.noOccItems[index] = changedItem;
        }

        const nonOccPrice =
            model.occView &&
            model.noOccItems.reduce((sum: number, item: LineItemI) => sum + (item.taxable_amt ?? 0), 0);
        const filteredTax =
            model.occView && model.noOccItems.reduce((sum: number, item: LineItemI) => sum + (item.total_tax ?? 0), 0);

        subtotals[0].account.map((item: any) => {
            const data = {
                subtotal: {
                    id: item.chargeToId,
                    row: [
                        "Subtotal",
                        (
                            item.requirementsListPrice +
                            item.occurrenceListPrice +
                            (model.occView ? 0 : item.occurrenceAdjustments)
                        ).toString(),
                        PricingService.formatCurrency(
                            model.occView
                                ? item.occurrenceAdjustments + PricingService.agg.adjustments.subtotal(model.noOccItems)
                                : item.profileAdjustments + item.requirementsAdjustments,
                        ),
                        (model.occView ? nonOccPrice + item.occurrenceTotalCharge : item.taxableAmount) //     ? item.occurrenceTotalCharge + item.requirementsTotalCharge + noOccTotalCharge // (model.occView
                            ?.toString(),
                        PricingService.formatCurrency(model.occView ? filteredTax : item.tax),
                        (model.occView
                            ? nonOccPrice + item.occurrenceTotalCharge + filteredTax
                            : item.eventsTotalCharge
                        ).toString(),
                        ...Array(5).fill(""),
                    ],
                },
                total: {
                    id: item.chargeToId,
                    row: [
                        "Total",
                        (item.occurrenceListPrice + item.requirementsListPrice + item.occurrenceAdjustments).toString(),
                        PricingService.formatCurrency(
                            item.profileAdjustments + item.grossAdjustments + item.requirementsAdjustments,
                        ),
                        (item.taxableAmount + item.grossAdjustments).toString(),
                        PricingService.formatCurrency(item.tax),
                        item.grandTotal.toString(),
                        ...Array(5).fill(""),
                    ],
                },
                adjustments: PricingService.getAdjustmentRows(model, item, adjustments, subtotals, eventLocatorMap),
            };

            if (model.combineRelatedEvents) {
                data.total.row.unshift("");
                data.subtotal.row.unshift("");
            }
            if (itemsMap[item.chargeToId]) itemsMap[item.chargeToId].footerData = data;
            return data;
        });

        if (!itemsMap.totals) itemsMap.totals = totals;
        itemsMap.bill_item_id = model.bill_item_id;
        itemsMap.adjustmentId = data.billItemId;
        itemsMap.orgId = model.orgId ?? model.charge_to_id;
        itemsMap.newChargeToId = data.newChargeToId;
        itemsMap.profileSubtotals = subtotals[0].accountProfile;

        return itemsMap;
    }

    public static getAdjustmentRows(model: any, lineItem: any, adjustments: any, subtotals: any, eventLocatorMap: any) {
        const { canAdjust, canEdit, canEditPricing, evBillId } = model;

        const adjustmentsMap: any = {};

        if (adjustments) {
            adjustments.map((adjustment: any) => {
                const adjustmentItem = {
                    ...model,
                    adjustment_amt: adjustment.adjustmentAmt,
                    adjustment_percent: adjustment.adjustmentPercent / 100,
                    adjustment_name: adjustment.adjustmentName,
                    charge_to_id: adjustment.chargeToId,
                    charge_to_name: model.charge_to_name,
                    bill_item_id: adjustment.itemId,
                    bill_item_type_id: -1,
                    total_charge: adjustment.totalCharge,
                    isAdjustment: true,
                };
                if (!adjustmentsMap[adjustment.chargeToId]) {
                    adjustmentsMap[adjustment.chargeToId] = [adjustmentItem];
                } else {
                    adjustmentsMap[adjustment.chargeToId].push(adjustmentItem);
                }
            });
        } else {
            adjustmentsMap[lineItem.chargeToId] = model.eventData.bill_item.filter((billItem: any) => {
                billItem.canAdjust = canAdjust;
                billItem.canEdit = canEdit;
                billItem.canEditPricing = canEditPricing;
                billItem.evBillId = evBillId;

                return eventLocatorMap[billItem.eventId];
            });
        }
        let data: any = [];
        const eventsArr = model.eventIds?.length ? model.eventIds : [model.eventId];
        eventsArr.forEach((eventId: any) => {
            let adjRow = [];
            model.combineRelatedEvents && adjRow.push(eventLocatorMap[eventId]);

            const allBillItems =
                eventId === model.eventId
                    ? adjustmentsMap[lineItem.chargeToId]?.filter((adj: any) => adj.eventId === eventId)
                    : model.eventData.bill_item.filter((item: any) => item.eventId === eventId);

            const filteredTax =
                model.occView &&
                model.noOccItems.reduce((sum: number, item: LineItemI) => sum + (item.total_tax ?? 0), 0);

            const profileSubtotals = subtotals[0].accountProfile.filter(
                (profile: AccountProfileSubTotal) => profile.chargeToId === (model.charge_to_id ?? model.orgId),
            );

            const taxDiff =
                model.occView &&
                PricingService.formatCurrency(
                    subtotals[0].account.find(
                        (profile: AccountSubTotal) => profile.chargeToId === (model.charge_to_id ?? model.orgId),
                    ).tax - filteredTax,
                );

            const rowData = [
                model.occView ? "Profile Entries:" : "Adjustments:",
                model.occView
                    ? {
                          type: "listPrice",
                          value: PricingService.formatCurrency(
                              profileSubtotals.reduce(
                                  (sum: number, item: LineItemI) => sum + item.occurrenceAdjustments,
                                  0,
                              ),
                          ),
                      }
                    : "",
                {
                    allBillItems: allBillItems?.length > 0 ? allBillItems : model.eventData.bill_item,
                    eventData: model.eventData,
                    eventIds: model.eventIds,
                    eventLocator: model.eventLocator,
                    canAdjust: canAdjust,
                    canEdit: canEdit,
                    canEditPricing: canEditPricing,
                    combineRelatedEvents: model.combineRelatedEvents,
                    evBillId: evBillId,
                    eventId: +eventId,
                    orgId: lineItem.chargeToId,
                    occView: model.occView,
                    profileSubtotals: profileSubtotals,
                    isOccAdjustment: S25Util.isDefined(model.rsrvId),
                    noOccItems: model.noOccItems,
                },
                "",
                model.occView
                    ? {
                          type: "tax",
                          value: taxDiff,
                      }
                    : "",
                {
                    allBillItems: allBillItems?.length > 0 ? allBillItems : model.eventData.bill_item,
                    evBillId,
                    orgId: lineItem.chargeToId,
                    profileSubtotals: profileSubtotals,
                    profileTax: model.occView ? taxDiff : null,
                    isOccAdjustment: S25Util.isDefined(model.rsrvId),
                    occView: model.occView,
                    eventData: model.eventData,
                    noOccItems: model.noOccItems,
                },
                ...Array(5).fill(""),
            ];

            data.push({ id: lineItem.chargeToId, row: [...adjRow, ...rowData] });
        });

        return data;
    }
}
